import { useEffect, useState } from "react";
import useErrors from "src/hooks/useErrors";
import fetchUser from "src/utils/fetchUser";

const useFetchSummary = (url) => {
  const [summary, setSummary] = useState({});
  const [balance, setBalance] = useState("");
  const handleErrors = useErrors();

  const fetchDataSummary = async () => {
    try {
      const { data, status } = await (await fetchUser(url)).data;

      if (status) {
        setSummary(data);
        setBalance("");
      }
    } catch (err) {
      handleErrors(err);
    }
  };
  useEffect(() => {
    fetchDataSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { summary, fetchDataSummary, balance, setBalance };
};

export default useFetchSummary;
