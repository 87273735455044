import { lazy } from "react";
import { Navigate } from "react-router";
import LogoOnlyLayout from "src/layouts/LogoOnlyLayout";
import MainLayout from "../layouts/main";
import Loadable from "./Loadable";
import LandingPage from "src/components/root_page/index.jsx";

const HomePage = Loadable(lazy(() => import("src/pages/Home")));
const About = Loadable(lazy(() => import("src/pages/About")));
const Contact = Loadable(lazy(() => import("src/pages/Contact")));
const ComingSoon = Loadable(lazy(() => import("src/pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("src/pages/Maintenance")));
const Pricing = Loadable(lazy(() => import("src/pages/Pricing")));
const Payment = Loadable(lazy(() => import("src/pages/Payment")));
const Page500 = Loadable(lazy(() => import("src/pages/Page500")));
const NotFound = Loadable(lazy(() => import("src/pages/Page404")));
const Success = Loadable(
  lazy(() => import("src/pages/userSide/dashboard/property-payment-success"))
);
const SuccessTarget = Loadable(
  lazy(() => import("src/routes/SuccessTarget"))
);

const main = [
  {
    path: "/",
    // element: <LandingPage />,
    children: [
      // { element: <HomePage />, index: true },
      { element: <Navigate to="/auth/login" />, index: true },
      { path: "bpract", element: <LandingPage /> },
      { path: "about-us", element: <About /> },
      { path: "contact-us", element: <Contact /> },
      { path: "world-success", element: <SuccessTarget /> },
    ],
  },
  {
    path: "*",
    element: <LogoOnlyLayout />,
    children: [
      { path: "success", element: <Success /> },
      { path: "coming-soon", element: <ComingSoon /> },
      { path: "maintenance", element: <Maintenance /> },
      { path: "pricing", element: <Pricing /> },
      { path: "payment", element: <Payment /> },
      { path: "500", element: <Page500 /> },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" replace /> },
    ],
  },
];

export default main;
