import SvgIconStyle from "src/components/SvgIconStyle";
import useAuth from "src/hooks/useAuth";
import useLocales from "src/hooks/useLocales";
import { PATH_USER } from "src/routes/paths";

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  whatsapp: getIcon("ic_whatsapp"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  booking: getIcon("ic_booking"),
  invoice: getIcon("ic_invoice"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  setting: getIcon("ic_settings"),
  online_store: getIcon("ic_store"),
  my_subscription: getIcon("ic_user_subscription"),
  business_builder: getIcon("ic_bb"),
  profile: getIcon("ic_profile"),
  help_center: getIcon("ic_helpcenter"),
  report: getIcon("ic_report"),
  tools: getIcon("ic_tools"),
};

const getNew = (isSilver, translate) => {
  let routes = [
    {
      title: translate("Livewebinar"),
      path: "https://us06web.zoom.us/j/89225537657?pwd=U9W0wta4lSBS9pdi7WZeOT81vBsyeS.1",
    },
    {
      title: translate("Startertraining"),
      path: "https://webinar.sparissimopartner.com/11920/21357/startertraining.html?mode=L&v=4",
    },
    {
      title: translate("Producttraining"),
      path: "https://webinar.sparissimopartner.com/11918/21357/produkteschulung.html?mode=L&v=4v",
    },
  ];

  if (isSilver) {
    routes = [
      ...routes,
      {
        title: translate("Leadercall"),
        path: "https://webinar.sparissimopartner.com/11919/21357/leadercall.html?mode=L&v=4",
      },
    ];
  }
  return routes;
};

const getChildren = (isSilver, isGold, translate) => {
  let routes = [
    { title: "Sponsor", path: PATH_USER.genealogy.sponsor },
    {
      title: translate("BronzeGenealogy"),
      path: PATH_USER.genealogy.binary,
    },
  ];

  if (isSilver) {
    routes = [
      ...routes,
      { title: "Silver genealogy", path: PATH_USER.genealogy.silver },
    ];
  }

  if (isGold) {
    routes = [
      ...routes,
      { title: "Gold genealogy", path: PATH_USER.genealogy.gold },
    ];
  }

  return routes;
};

const useNavConfig = () => {
  const { user } = useAuth();
  const { translate } = useLocales();

  return [
    {
      items: [
        {
          is_payment: true,
          title: translate("dashboard"),
          path: PATH_USER.user_dashboard,
          icon: ICONS.dashboard,
        },

        {
          is_payment: false,
          title: translate("joinTelegram"),
          path: "https://t.me/+NJR3FZ2N2iw2MmI8",
          icon: ICONS.chat,
        },
        {
          is_payment: false,
          title: translate("joinWhatsapp"),
          path: "https://chat.whatsapp.com/FRsmikrgXYT3NPAZpKvYCX",
          icon: ICONS.whatsapp,
        },
        // {
        //   title: translate("onlineStore"),
        //   path: PATH_USER.onlineStore.root,
        //   icon: ICONS.online_store,
        //   children: [
        //     {
        //       title: translate("package"),
        //       path: PATH_USER.onlineStore.productSubscription.root,
        //     },
        //     { title: translate("myOrders"), path: PATH_USER.my_orders.root },
        //   ],
        // },
        // {
        //   title: translate("mySubscriptions"),
        //   icon: ICONS.my_subscription,
        //   path: PATH_USER.subscriptions.root,
        // },
        {
          is_payment: false,
          title: translate("genealogy"),
          icon: ICONS.kanban,
          path: PATH_USER.genealogy.root,
          children: getChildren(user.silverCheck, user.goldCheck, translate),
        },
        {
          is_payment: false,
          title: translate("financial"),
          path: PATH_USER.financial.root,
          icon: ICONS.ecommerce,
          children: [
            {
              title: translate("myEWallet"),
              path: PATH_USER.financial.eWallet,
              property_purchase: true,
            },
            // {
            //   title: translate("depositWallet"),
            //   path: PATH_USER.financial.depositWallet,
            // },
            {
              title: translate("fundTransfer"),
              path: PATH_USER.financial.fundsTransfer,
              property_purchase: false,
            },
            {
              title: translate("requestPayout"),
              path: PATH_USER.financial.payout,
              property_purchase: true,
            },
            {
              title: translate("RestaurantSponsoring"),
              path: PATH_USER.financial.restaurantSponsoring,
              property_purchase: false,
            },
            {
              title: translate("booster"),
              path: PATH_USER.financial.apiBooster,
              property_purchase: false,
            },
            {
              title: translate("propertyPool"),
              path: PATH_USER.financial.propertyPool,
              property_purchase: true,
            },
          ],
        },
        // {
        //   title: translate("businessBuilder"),
        //   icon: ICONS.business_builder,
        //   path: PATH_USER.business_builder.root,
        //   children: [
        //     {
        //       title: translate("subscriptions"),
        //       path: PATH_USER.business_builder.subscriptions,
        //     },
        //     {
        //       title: translate("materials"),
        //       path: PATH_USER.business_builder.materials.root,
        //     },
        //   ],
        // },
        {
          is_payment: true,
          title: translate("notice.nav_menu"),
          icon: ICONS.calendar,
          path: PATH_USER.notice.root,
        },
        {
          is_payment: true,
          title: translate("myProfile"),
          icon: ICONS.profile,
          path: PATH_USER.profile.root,
        },
        {
          is_payment: false,
          title: translate("blogs"),
          icon: ICONS.blog,
          path: PATH_USER.blogs.root,
        },
        {
          is_payment: true,
          title: translate("helpCenter"),
          path: PATH_USER.helpCenter.root,
          icon: ICONS.help_center,
          children: [
            {
              title: translate("faqs"),
              path: PATH_USER.helpCenter.faq.root,
            },
            // {
            //   title: translate("knowledgeBase"),
            //   path: PATH_USER.helpCenter.knowledgeBase.root,
            // },
            // {
            //   title: translate("emails"),
            //   path: PATH_USER.helpCenter.mails.all,
            // },
            {
              title: translate("createTicket"),
              path: PATH_USER.helpCenter.createTicket.subCategory(),
            },
          ],
        },

        {
          is_payment: true,
          title: translate("Tools"),
          path: PATH_USER.tools.root,
          icon: ICONS.tools,
          children: [
            // {
            //   title: translate("Webinar"),
            //   children: getNew(user.silverCheck, translate),
            // },
            {
              title: translate("Documents"),
              path: PATH_USER.tools.documents,
            },
            {
              title: translate("Videos"),
              path: PATH_USER.tools.videos,
            },
          ],
        },

        {
          is_payment: false,
          title: translate("incomeReport"),
          path: PATH_USER.incomeReport.root,
          icon: ICONS.report,
        },
      ],
    },
  ];
};

/**
 * This build the navbar fo r the user side, each index will be a section on the side panel.
 * The object takes in the following keys:
 *  - subheader : for title of the section
 *  - items     : for taking in nav items
 *      - title : string
 *      - path  : link
 *      - icon  : icon
 *      - children: items[]
 */

export default useNavConfig;
